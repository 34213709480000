import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";
import H2 from "../components/styled/H2";
import H4 from "../components/styled/H4";
import colors from "../components/colors";
import "./md-page.css";

const Center = styled.div`
  width: 100%;
  text-align: center;
  * {
    max-width: 100%;
  }
`;

const PageBody = styled.div`
  color: ${colors.dark};
  padding-top: 2.5em;
  img {
    max-width: 100%;
  }
`;

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} keywords={["gatsby", "application", "react"]} />
      <Section>
        <Center>
          <H2>{frontmatter.title}</H2>
          {frontmatter.legal && <H4>Last updated on {frontmatter.date}</H4>}
        </Center>
        <PageBody className="legal-content" dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        legal
      }
    }
  }
`;
